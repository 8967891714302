import React from "react";
import {Button} from "carbon-components-react";
import { ArrowLeft16 } from "@carbon/icons-react";
import { navigate, useStaticQuery, graphql } from "gatsby";
import ReactHtmlParser from 'react-html-parser'

function About({ data }) {
  let qry = useStaticQuery(graphql`
    {
      nodeArticle {
        title
        body {
          processed
          value
        }
      }
    }
  `)
  let abouttitle = qry.nodeArticle.title
  let aboutcontent = qry.nodeArticle.body.value
  const aboutLayout = (
    <div className={`application-wrapper application`}>
      <div className="bx--row">
        <div id="left-panel" className="bx--col-lg-4">
          <div>
            <Button kind="tertiary" onClick={(e) => navigate('/')}>
              <ArrowLeft16 /> <span> Go Back</span>
            </Button>
          </div>
        </div>
        <div id="right-panel" className="bx--col-lg-8">
          <div className="padd txt" align='center'>
            <div id="application-title" className="padd">
              <h2 align='center'><strong>{abouttitle}</strong></h2>
            </div>
            <br/>
            {ReactHtmlParser(aboutcontent)}
          </div>
          <br/>
        </div>
      </div>
    </div>
  );
  return aboutLayout;
}

export default function aboutPage({ pageContext, location }) {
  pageContext = {
    ...pageContext,
    frontmatter: { title: "NSSSP Application", tabs: ["Profile", "About"] },
  };
  return (
    <About
      pageContext={pageContext}
      location={location}
    />
  );
}